import { ResponsiveSwarmPlot } from "@nivo/swarmplot";
import { Pipeline } from "../../classes/pipeline";
import React from "react";
import './kpi_box.css';


interface SwarmPlotBoxProps {
    data: Pipeline[];
    pipelineColors: string[]
}

const SwarmPlotBox: React.FC<SwarmPlotBoxProps> = ({ data, pipelineColors }) => {

    let pipelines = data.map(obj => ({ ...obj, position: '350', group: obj.name, size: obj.pipelineMetrics.size, displaySize: (Math.log2(obj.pipelineMetrics.size) - 33) * 4, readableSize: obj.pipelineMetrics.hrSize }));

    const CustomTooltip = (data: any) => {
        return (
            <div className="kpi-custom-tooltip">
                <span>Model Size: <span style={{ color: data.color }}>{pipelines[data.index].readableSize} Parameters</span> </span>
            </div>
        );
    };

    const CustomTick: React.FC<{ x: number; y: number; value?: string, tickIndex: number }> = ({ x, y, value, tickIndex }) => {

        if (data[tickIndex] === undefined) {
            return <></>
        }

        const hrSize = data[tickIndex].pipelineMetrics.hrSize;

        const words = value ? value.split(' ') : [];

        return (
            <g transform={`translate(${x},${y})`}>
                <text
                    x={0}
                    y={20}
                    dy={16}
                    textAnchor="middle"
                    fontSize={15}
                    fill="black"
                >
                    {hrSize}
                </text>
                <text
                    x={0}
                    y={55}
                    dy={16}
                    fontSize={13}
                    textAnchor="middle"
                    fill="#757575"
                >
                    {words.map((word, index) => (
                        <tspan key={index} x={0} dy={index === 0 ? "0" : "1.2em"}>{word}</tspan>
                    ))}
                </text>
            </g>
        );
    };


    return (
        <>

            {
                pipelines.length !== 0 ?
                    <ResponsiveSwarmPlot
                        colors={pipelines.map((_, index) => pipelineColors[index % pipelineColors.length])}
                        data={pipelines}
                        groups={pipelines.map(pipeline => pipeline.group)}
                        value="position"
                        valueFormat=".2f"
                        valueScale={{ type: 'linear', min: 0, max: 500, reverse: false }}
                        theme={{
                            axis:
                            {
                                ticks: {
                                    text: {
                                        fontFamily: "'DM Mono', 'monospace', 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif'"
                                    }
                                }
                            }
                        }}
                        size={{
                            key: 'displaySize',
                            values: [
                                4,
                                20
                            ],
                            sizes: [
                                25,
                                110
                            ]
                        }}
                        enableGridX={false}
                        enableGridY={false}
                        forceStrength={4}
                        simulationIterations={100}
                        borderColor={{
                            from: 'color',
                            modifiers: [
                                [
                                    'darker',
                                    0.6
                                ],
                                [
                                    'opacity',
                                    0.5
                                ]
                            ]
                        }}
                        margin={{ top: 100, right: 100, bottom: 100, left: 100 }}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                            tickSize: 0,
                            tickPadding: 5,
                            tickRotation: 0,
                            legendPosition: 'middle',
                            legendOffset: 0,
                            truncateTickAt: 0,
                            renderTick: props => <CustomTick {...props} />,
                        }}
                        axisLeft={null}
                        tooltip={CustomTooltip}
                    />
                    : <></>
            }</>
    );
}

export default SwarmPlotBox;
