import React from 'react';
import './popup_bubble.css';

interface PopupBubbleProps {
    itemTitle: string;
    isActive?: boolean
}

const PopupBubble: React.FC<PopupBubbleProps> = ({ itemTitle, isActive = false }) => {
    return (
        <div className={`popup-bubble custom-font ${isActive ? "popup-bubble-enabled" : "popup-bubble-disabled"}`}>{itemTitle}</div>
    );
}

export default PopupBubble;
