import { PopupElement } from "./popup_element";

export class RagConfig {

    public expansion: PopupElement[];
    public search: PopupElement[];
    public ranking: PopupElement[];
    public generation: PopupElement[];

    constructor(
        expansion: PopupElement[],
        search: PopupElement[],
        ranking: PopupElement[],
        generation: PopupElement[]) {

        this.expansion = expansion;
        this.search = search;
        this.ranking = ranking;
        this.generation = generation;
    }



}


