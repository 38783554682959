export class PipelineMetrics {

    public size: number;
    public hrSize: string;
    public latency: object;
    public factual_accuracy: number;

    constructor(
        size: number,
        hrSize: string,
        latency: object,
        factual_accuracy: number) {

        this.size = size;
        this.hrSize = hrSize;
        this.latency = latency;
        this.factual_accuracy = factual_accuracy;
    }
}


