import EventEmitter from "events";

class JSONStreamParser extends EventEmitter {
    private buffer: string = '';
    private controller: AbortController | null = null;

    constructor(private url: string) {
        super();
        this.fetchStream();
    }

    public abort() {
        if (this.controller) {
            this.controller.abort();
        }
    }

    private async fetchStream() {
        try {
            this.controller = new AbortController();
            const response = await fetch(this.url, { signal: this.controller.signal });
            const reader = response.body!.getReader();
            while (true) {
                const { done, value } = await reader.read();
                if (done) {
                    this.emit('end');
                    break;
                }
                const chunk = new TextDecoder().decode(value);
                this.buffer += chunk;
                this.parseBuffer();
            }
        } catch (err) {
            this.emit('error', err);
        }
    }

    private parseBuffer() {
        const lines = this.buffer.split('\n');
        // Keep the last line in the buffer since it might be incomplete
        this.buffer = lines.pop() || '';
        for (const line of lines) {
            this.parseJSON(line.trim());
        }
    }

    private parseJSON(jsonStr: string) {
        try {
            const obj = JSON.parse(jsonStr);
            // Emit the parsed object
            this.emit('object', obj);
        } catch (err) {
            // JSON parse error, emit error event
            this.emit('error', err);
        }
    }
}

export default JSONStreamParser;
