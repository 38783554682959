import React from 'react';
import './pipeline_vertical_container.css';


interface PipelineVerticalContainerProps {
    children: React.ReactNode
}

const PipelineVerticalContainer: React.FC<PipelineVerticalContainerProps> = ({ children }) => {

    return (
        <div className='pipeline-vertical-container'>
            {children}
        </div>
    );
}

export default PipelineVerticalContainer;
