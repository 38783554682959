import React from 'react';
import './pipeline_vertical_container_row.css';


interface PipelineVerticalContainerRowProps {
    children: React.ReactNode
}

const PipelineVerticalContainerRow: React.FC<PipelineVerticalContainerRowProps> = ({ children }) => {

    return (
        <div className='pipeline-vertical-container-row'>
            {children}
        </div>
    );
}

export default PipelineVerticalContainerRow;
