import { useState } from "react";
import { CustomSelect } from "../../utils/select_input/custom_select";
import "./intro_box.css"
import IntroSelect from "./instro_select";
import { useNavigate } from "react-router-dom";

let options: CustomSelect[] = [
  new CustomSelect("product_select", "Product", ["Distilled Question Answering", "Distilled Structured Generaton", "Distilled document editing"]),
  new CustomSelect("knowledge_source_select", "Knowledge Sources", ["Covid-19 Articles", "Finantial Reports", "Patents"]),
  new CustomSelect("evaluation_data_select", "Evaluation Data", ["50 x Covid-19 Questions"])
]

const IntroBox = () => {

  const [selectedOptionState, setSelectedOptionState] = useState<{ [key: string]: string | undefined }>(options.reduce((acc, option) => ({ ...acc, ...option.generateSelectState() }), {}));

  const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newState = { ...selectedOptionState };
    newState[event.target.name] = event.target.value;
    setSelectedOptionState(newState);
  };

  let navigate = useNavigate();

  const onClick = () => {
    const formFilled: boolean = Object.values(selectedOptionState).every(value => value !== undefined);

    if (formFilled) {
      const path = `/dashboard`;
      navigate(path, { state: selectedOptionState });
    } else {
      alert("All fields must be provided!");
    }
  };


  return (
    <div className="intro-box ">
      <p className="medium-title">Create a new pipeline</p>
      {options.map(option => <IntroSelect selected_value={selectedOptionState[option.select_name]} custom_select={option} onChange={onChange}></IntroSelect>)
      }
      <input className="intro-submit medium-button" type="submit" value="Submit" onClick={onClick}></input>
    </div >
  );
}

export default IntroBox;
