import React from 'react';
import './pipeline_horizontal_container.css';


interface PipelineHorizontalContainerProps {
    children: React.ReactNode
}

const PipelineHorizontalContainer: React.FC<PipelineHorizontalContainerProps> = ({ children }) => {

    return (
        <div className='pipeline-horizontal-container'>
            {children}
        </div>
    );
}

export default PipelineHorizontalContainer;
