
var SHA256 = require("crypto-js/sha256");

export class IdentifiableObject {

    public id: string
    private IDLength: number

    constructor(id?: string, hashID: boolean = true, IDLength: number = 10) {
        this.IDLength = IDLength;
        this.id = hashID ? this.generateID(id) : id ?? this.generateID();
    }

    protected generateID(s?: string): string {
        let uniqueString = s;
        if (uniqueString === undefined || uniqueString.trim() === "") {
            const timestamp = Date.now().toString();
            const randomInteger = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
            uniqueString = timestamp + '-' + randomInteger;
        }

        const hash = SHA256(uniqueString).toString();
        return hash.substring(0, this.IDLength);
    }
}


