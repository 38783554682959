import React from 'react';
import './complex_popup_item.css';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ComplexPopupItemProps {
    itemTitle: string;
    subTitle?: string;
    itemIcon?: string | IconProp | React.ReactNode;
    onClick: () => void
}

const ComplexPopupItem: React.FC<ComplexPopupItemProps> = ({ itemTitle, subTitle, itemIcon, onClick }) => {
    return (
        <div className='popup-item-box cursor-pointer' onClick={onClick}>
            <div className="popup-item-title">{itemTitle}</div>
            <div className='popup-item-box-footer'>
                <div className="popup-item-size" style={subTitle ? { visibility: 'visible' } : { visibility: 'hidden' }}>{subTitle}</div>
                <div className='popup-item-icon'>
                    {itemIcon && typeof itemIcon === 'string' ? (
                        <img src={itemIcon} alt="Model Icon" />
                    ) : (
                        React.isValidElement(itemIcon) ? (
                            itemIcon
                        ) : (
                            <FontAwesomeIcon icon={itemIcon as IconProp} />
                        )
                    )}
                </div>
            </div>
        </div >
    );
}

export default ComplexPopupItem;
