import React from 'react';
import './pipeline_add_item.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faPlusCircle } from '@fortawesome/free-solid-svg-icons';

interface PipelineAddItemProps {
    isEnabled?: boolean
    isActive?: boolean
}

const PipelineAddItem: React.FC<PipelineAddItemProps> = ({ isEnabled = true, isActive = false }) => {
    return (
        <div className={`pipeline-add-item-box ${isActive ? "pipeline-add-item-box-active" : ""} ${isEnabled ? 'pipeline-add-item-box-enabled' : 'pipeline-add-item-box-disabled'}`}>
            <div className='pipeline-model-icon pipeline-model-icon-medium'>
                <FontAwesomeIcon icon={isEnabled ? faPlusCircle : faBan} />
            </div>
        </div >
    );
}

export default PipelineAddItem;
