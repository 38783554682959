import React from 'react';
import './pipeline_config.css';
import TextBox from '../text_box/text_box';
import { faCircleQuestion, faFileLines, faMessage } from '@fortawesome/free-regular-svg-icons';
import PipelineItemWrapper from './pipeline_items/pipeline_item_wrapper';
import { faCirclePlus, faMagnifyingGlass, faPencil, faSignal } from '@fortawesome/free-solid-svg-icons';
import PipelineModel from './pipeline_items/pipeline_model';
import PipelinePin from './pipeline_items/pipeline_pin';
import PipelineVerticalContainer from './pipeline_items/pipeline_vertical_container';
import PipelineVerticalContainerRow from './pipeline_items/pipeline_vertical_container_row';
import PipelineHorizontalContainer from './pipeline_items/pipeline_horizontal_container';
import CustomPopup, { PopupDisplayType } from '../popup/custom_popup';
import PipelineAddItem from './pipeline_items/pipeline_add_item';
import { PipelineConfigState } from '../../classes/pipeline_config_state';
import { RagConfig } from '../../classes/rag_config';
import { Pipeline } from '../../classes/pipeline';
import axios from 'axios';
import { PipelineMetrics } from '../../classes/pipeline_metrics';
import { PopupElement } from '../../classes/popup_element';
import { ReactComponent as MistralIcon } from "../../assets/icons/mistral.svg";
import { ReactComponent as OpenAIIcon } from "../../assets/icons/open_ai.svg";
import { ReactComponent as ShieldIcon } from "../../assets/icons/protected.svg";
import { ReactComponent as DistilledMaltIcon } from "../../assets/icons/distil_default.svg";
import { ReactComponent as LockIcon } from "../../assets/icons/lock.svg";
import { ReactComponent as UnlockIcon } from "../../assets/icons/unlock.svg";
import { ReactComponent as OpenSearchIcon } from "../../assets/icons/opensearch.svg";
import { environment } from '../../utils/environment';



interface PipelineConfigProps {
  createNewPipeline: (pipeline: Pipeline) => void;
  selectedPipeline?: Pipeline;
  isDistilTabActive: boolean;
  queryExpansionItems: PopupElement[]
  initialSearchResultsItems: PopupElement[]
  rankedSearchResultsItems: PopupElement[]

}

interface PipelineConfigReactState {
  openPopup?: string;
  availablePipelineConfigState: { [key: string]: PopupElement[] };
  selectedPipelineConfigState: { [key: string]: PopupElement[] };
}


class PipelineConfig extends React.Component<PipelineConfigProps, PipelineConfigReactState> {
  private configStates: { [key: string]: PipelineConfigState } = {
    expansion: new PipelineConfigState("expansion", 1, [
      new PopupElement("Mistral Small", "7B", <MistralIcon />),
      new PopupElement("GPT4", "1.7TR", <OpenAIIcon />),
      new PopupElement("Mistral Large", "7x8B", <MistralIcon />),
      new PopupElement("Distil Mistral", "7B", <DistilledMaltIcon />)
    ]),

    search: new PipelineConfigState("search", 3, [
      new PopupElement("BM25", undefined, <OpenSearchIcon />),
      new PopupElement("Mini-LM", "22M", <ShieldIcon />),
      new PopupElement("Epic", "67M", <ShieldIcon />)
    ]),
    ranking: new PipelineConfigState("ranking", 1, [
      new PopupElement("GPT4", "1.7TR", <OpenAIIcon />),
      new PopupElement("Mistral Large", "7x8B", <MistralIcon />),
      new PopupElement("Distil T5", "1B", <DistilledMaltIcon />),
    ]),
    generation: new PipelineConfigState("generation", 1, [
      new PopupElement("Distil Mistral", "7B", <DistilledMaltIcon />),
      new PopupElement("Mistral Large", "7x8B", <MistralIcon />),
      new PopupElement("Mistral Small", "7B", <MistralIcon />),
      new PopupElement("GPT4", "1.7TR", <OpenAIIcon />)
    ])
  }

  constructor(props: PipelineConfigProps) {
    super(props)


    this.state = {
      openPopup: undefined,
      availablePipelineConfigState: {
        expansion: this.configStates.expansion.options,
        search: this.configStates.search.options,
        ranking: this.configStates.ranking.options,
        generation: this.configStates.generation.options,
      },
      selectedPipelineConfigState: {
        expansion: Array(this.configStates.expansion.nSections).fill(undefined),
        search: Array(this.configStates.search.nSections).fill(undefined),
        ranking: Array(this.configStates.ranking.nSections).fill(undefined),
        generation: Array(this.configStates.generation.nSections).fill(undefined),
      }
    }
  }


  componentDidUpdate(prevProps: Readonly<PipelineConfigProps>, prevState: Readonly<PipelineConfigReactState>, snapshot?: any): void {

    if (this.props.selectedPipeline !== undefined && this.props.selectedPipeline !== prevProps.selectedPipeline) {
      this.setState({
        selectedPipelineConfigState: {
          expansion: [...this.props.selectedPipeline!.pipelineConfig.expansion],
          search: [...this.props.selectedPipeline!.pipelineConfig.search],
          ranking: [...this.props.selectedPipeline!.pipelineConfig.ranking],
          generation: [...this.props.selectedPipeline!.pipelineConfig.generation],
        },
        availablePipelineConfigState: {
          expansion: this.configStates.expansion.options.filter(item => !this.props.selectedPipeline!.pipelineConfig.expansion.includes(item)),
          search: this.configStates.search.options.filter(item => !this.props.selectedPipeline!.pipelineConfig.search.includes(item)),
          ranking: this.configStates.ranking.options.filter(item => !this.props.selectedPipeline!.pipelineConfig.ranking.includes(item)),
          generation: this.configStates.generation.options.filter(item => !this.props.selectedPipeline!.pipelineConfig.generation.includes(item)),
        }
      })
    }


  }



  private handleTogglePopup = (popupId?: string, modelId?: string) => {
    this.setState({
      openPopup: this.state.openPopup === popupId ? undefined : popupId
    })

    if (popupId !== undefined && modelId !== undefined) {
      const [pipelineID, itemIDStr] = popupId.split("_")
      let itemID = parseInt(itemIDStr)

      let currentModel = this.state.selectedPipelineConfigState[pipelineID][itemID]
      if (currentModel === undefined && modelId !== "remove") {
        let selectedModel = this.state.availablePipelineConfigState[pipelineID].find(obj => obj.id === modelId);
        let newSelectedPipelineConfigState = { ...this.state.selectedPipelineConfigState }
        newSelectedPipelineConfigState[pipelineID][itemID] = selectedModel!

        let newAvailablePipelineConfigState = { ...this.state.availablePipelineConfigState }
        newAvailablePipelineConfigState[pipelineID] = this.state.availablePipelineConfigState[pipelineID].filter(obj => obj.id !== modelId);

        this.setState({
          availablePipelineConfigState: newAvailablePipelineConfigState,
          selectedPipelineConfigState: newSelectedPipelineConfigState
        })
      } else {

        if (modelId === "remove") {
          if (currentModel !== undefined) {

            let newSelectedPipelineConfigState = { ...this.state.selectedPipelineConfigState }
            newSelectedPipelineConfigState[pipelineID][itemID] = undefined!;

            let newAvailablePipelineConfigState = { ...this.state.availablePipelineConfigState }
            newAvailablePipelineConfigState[pipelineID].push(currentModel)

            this.setState({
              availablePipelineConfigState: newAvailablePipelineConfigState,
              selectedPipelineConfigState: newSelectedPipelineConfigState
            })
          }

        } else {
          let selectedModel = this.state.availablePipelineConfigState[pipelineID].find(obj => obj.id === modelId);

          let newSelectedPipelineConfigState = { ...this.state.selectedPipelineConfigState }

          newSelectedPipelineConfigState[pipelineID][itemID] = selectedModel!

          let newAvailablePipelineConfigState = { ...this.state.availablePipelineConfigState }
          newAvailablePipelineConfigState[pipelineID] = this.state.availablePipelineConfigState[pipelineID].filter(obj => obj.id !== modelId);
          newAvailablePipelineConfigState[pipelineID].push(currentModel)

          this.setState({
            availablePipelineConfigState: newAvailablePipelineConfigState,
            selectedPipelineConfigState: newSelectedPipelineConfigState
          })
        }
      }
    }

  };

  private handleInputTextSubmit = (value: string) => {
    if (value.trim() !== "") {
      console.log(`${process.env.BACKEND_BASE_URL}:${process.env.BACKEND_PORT}`);
      // Perform call to get metrics
      axios.get(`${environment.baseUrl}/metrics/get_metrics`, { params: { 'pipeline_id': value.toLowerCase().replace(" ", "_") } })
        .then(response => {

          let pipeline = new Pipeline(
            value,
            new PipelineMetrics(response.data['model_size'], response.data['model_size_reduced'], response.data['latency'], response.data['factual_accuracy']),
            new RagConfig(
              [...this.state.selectedPipelineConfigState.expansion],
              [...this.state.selectedPipelineConfigState.search],
              [...this.state.selectedPipelineConfigState.ranking],
              [...this.state.selectedPipelineConfigState.generation]))
          this.props.createNewPipeline(pipeline);

        })
        .catch(error => {
          console.error(error);
        });
    }
  }

  render() {
    return (
      <div className="dashboard-box content-div-style" >
        <div className="dashboard-box-content">
          <p className="small-title">PIPELINE CONFIGURATION</p>
          <div className="input-box-container">
            <TextBox isEnabled={this.props.isDistilTabActive} placeholder="Enter pipeline name" onSubmit={this.handleInputTextSubmit} buttonText={this.props.isDistilTabActive ? "Evaluate" : "Evaluated"} />
          </div>
          <div className='pipeline-diagram'>
            <PipelineVerticalContainer>
              <PipelineVerticalContainerRow>
                <PipelineItemWrapper title={"Question"} icon={faCircleQuestion} backgroundColor='rgba(242,242,242,1)' isEnabled={false}>
                  <div className="pipeline-source-box-title">Industry-specific question</div>
                </PipelineItemWrapper>
                <PipelinePin rightPin={true}></PipelinePin>
              </PipelineVerticalContainerRow>
              <PipelineVerticalContainerRow>
                <PipelineItemWrapper title={"Documents"} icon={faFileLines} backgroundColor='rgba(242,242,242,1)' isEnabled={false}>
                  <div className="pipeline-source-box-title">Knowledge Sources</div>
                </PipelineItemWrapper>
                <PipelinePin rightPin={true}></PipelinePin>
              </PipelineVerticalContainerRow>
            </PipelineVerticalContainer>
            <PipelineHorizontalContainer>
              <PipelinePin></PipelinePin>
              <PipelineVerticalContainer>
                <PipelineVerticalContainerRow>
                  <CustomPopup
                    key={`expansion_wrapper`}
                    isOpen={this.state.openPopup === `expansion_wrapper`}
                    popupID={`expansion_wrapper`}
                    onToggle={this.handleTogglePopup}
                    options={this.props.queryExpansionItems}
                    isEnabled={!this.props.isDistilTabActive && this.props.queryExpansionItems.length !== 0}
                    displayType={PopupDisplayType.BubbleListView}
                  >
                    <PipelineItemWrapper
                      title='Expansion'
                      icon={faCirclePlus}
                      isActive={this.state.openPopup === `expansion_wrapper`}
                      isEnabled={!this.props.isDistilTabActive && this.props.queryExpansionItems.length !== 0}>
                      {this.state.selectedPipelineConfigState.expansion.map((selection, index) => {
                        const hasModel = selection !== undefined
                        return (
                          <div key={`expansion_${index}`} onClick={(e) => e.stopPropagation()}>
                            <CustomPopup
                              key={`expansion_${index}`}
                              isOpen={this.state.openPopup === `expansion_${index}`}
                              popupID={`expansion_${index}`}
                              onToggle={this.handleTogglePopup}
                              options={this.state.availablePipelineConfigState.expansion}
                              isEnabled={this.props.isDistilTabActive}
                            >
                              {hasModel ? (
                                <PipelineModel
                                  modelName={this.state.selectedPipelineConfigState.expansion[index].itemTitle}
                                  modelSize={this.state.selectedPipelineConfigState.expansion[index].subTitle}
                                  modelIcon={this.state.selectedPipelineConfigState.expansion[index].itemIcon}
                                  isActive={this.state.openPopup === `expansion_${index}`}
                                  isEnabled={this.props.isDistilTabActive}
                                />
                              ) : (
                                <PipelineAddItem isEnabled={this.props.isDistilTabActive} isActive={this.state.openPopup === `expansion_${index}`} />
                              )}
                            </CustomPopup>
                          </div>
                        );
                      })}
                    </PipelineItemWrapper>
                  </CustomPopup>
                </PipelineVerticalContainerRow>
              </PipelineVerticalContainer>
              <PipelinePin rightPin={true}></PipelinePin>
              <PipelineVerticalContainer>
                <PipelineVerticalContainerRow>
                  <CustomPopup
                    key={`search_wrapper`}
                    isOpen={this.state.openPopup === `search_wrapper`}
                    popupID={`search_wrapper`}
                    onToggle={this.handleTogglePopup}
                    options={this.props.initialSearchResultsItems}
                    isEnabled={!this.props.isDistilTabActive && this.props.initialSearchResultsItems.length !== 0}
                    displayType={PopupDisplayType.OrderedListView}
                    isOrderedListRanked={true}
                    orderedListTitle='Document id'
                    orderedListStatusLabel='Validity'
                  >
                    <PipelineItemWrapper
                      title='Search'
                      icon={faMagnifyingGlass}
                      isActive={this.state.openPopup === `search_wrapper`}
                      isEnabled={!this.props.isDistilTabActive && this.props.initialSearchResultsItems.length !== 0}>
                      {this.state.selectedPipelineConfigState.search.map((selection, index) => {
                        const hasModel = selection !== undefined
                        return (
                          <div key={`search_${index}`} onClick={(e) => e.stopPropagation()}>
                            <CustomPopup
                              key={`search_${index}`}
                              isOpen={this.state.openPopup === `search_${index}`}
                              popupID={`search_${index}`}
                              onToggle={this.handleTogglePopup}
                              options={this.state.availablePipelineConfigState.search}
                              isEnabled={this.props.isDistilTabActive}
                            >
                              {hasModel ? (
                                <PipelineModel
                                  modelName={this.state.selectedPipelineConfigState.search[index].itemTitle}
                                  modelSize={this.state.selectedPipelineConfigState.search[index].subTitle}
                                  modelIcon={this.state.selectedPipelineConfigState.search[index].itemIcon}
                                  isActive={this.state.openPopup === `search_${index}`}
                                  isEnabled={this.props.isDistilTabActive}
                                />
                              ) : (
                                <PipelineAddItem isEnabled={this.props.isDistilTabActive} isActive={this.state.openPopup === `search_${index}`} />
                              )}
                            </CustomPopup>
                          </div>
                        );
                      })}
                    </PipelineItemWrapper>
                  </CustomPopup>
                </PipelineVerticalContainerRow>
              </PipelineVerticalContainer>
              <PipelinePin rightPin={true}></PipelinePin>
              <PipelineVerticalContainer>
                <PipelineVerticalContainerRow>
                  <CustomPopup
                    key={`ranking_wrapper`}
                    isOpen={this.state.openPopup === `ranking_wrapper`}
                    popupID={`ranking_wrapper`}
                    onToggle={this.handleTogglePopup}
                    options={this.props.rankedSearchResultsItems}
                    isEnabled={!this.props.isDistilTabActive && this.props.rankedSearchResultsItems.length !== 0}
                    displayType={PopupDisplayType.OrderedListView}
                    orderedListTitle='Document id'
                    orderedListStatusLabel='Validity'
                    isOrderedListRanked={true}
                  >
                    <PipelineItemWrapper
                      title='Ranking'
                      icon={faSignal}
                      isActive={this.state.openPopup === `ranking_wrapper`}
                      isEnabled={!this.props.isDistilTabActive && this.props.rankedSearchResultsItems.length !== 0}>
                      {this.state.selectedPipelineConfigState.ranking.map((selection, index) => {
                        const hasModel = selection !== undefined
                        return (
                          <div key={`ranking_${index}`} onClick={(e) => e.stopPropagation()}>
                            <CustomPopup
                              key={`ranking_${index}`}
                              isOpen={this.state.openPopup === `ranking_${index}`}
                              popupID={`ranking_${index}`}
                              onToggle={this.handleTogglePopup}
                              options={this.state.availablePipelineConfigState.ranking}
                              isEnabled={this.props.isDistilTabActive}
                            >
                              {hasModel ? (
                                <PipelineModel
                                  modelName={this.state.selectedPipelineConfigState.ranking[index].itemTitle}
                                  modelSize={this.state.selectedPipelineConfigState.ranking[index].subTitle}
                                  modelIcon={this.state.selectedPipelineConfigState.ranking[index].itemIcon}
                                  isActive={this.state.openPopup === `ranking_${index}`}
                                  isEnabled={this.props.isDistilTabActive}
                                />
                              ) : (
                                <PipelineAddItem isEnabled={this.props.isDistilTabActive} isActive={this.state.openPopup === `ranking_${index}`} />
                              )}
                            </CustomPopup>
                          </div>
                        );
                      })}
                    </PipelineItemWrapper>
                  </CustomPopup>
                </PipelineVerticalContainerRow>
              </PipelineVerticalContainer>
              <PipelinePin></PipelinePin>
            </PipelineHorizontalContainer>
            <PipelineVerticalContainer>
              <PipelineVerticalContainerRow>
                <PipelinePin rightPin={true} ></PipelinePin>
                <PipelineItemWrapper title='Generation' icon={faPencil} isEnabled={false}>
                  {this.state.selectedPipelineConfigState.generation.map((selection, index) => {
                    const hasModel = selection !== undefined
                    return (
                      <div key={`generation_${index}`} onClick={(e) => e.stopPropagation()}>
                        <CustomPopup
                          key={`generation_${index}`}
                          isOpen={this.state.openPopup === `generation_${index}`}
                          popupID={`generation_${index}`}
                          onToggle={this.handleTogglePopup}
                          options={this.state.availablePipelineConfigState.generation}
                          isEnabled={this.props.isDistilTabActive}
                        >
                          {hasModel ? (
                            <PipelineModel
                              modelName={this.state.selectedPipelineConfigState.generation[index].itemTitle}
                              modelSize={this.state.selectedPipelineConfigState.generation[index].subTitle}
                              modelIcon={this.state.selectedPipelineConfigState.generation[index].itemIcon}
                              isActive={this.state.openPopup === `generation_${index}`}
                              isEnabled={this.props.isDistilTabActive}
                            />
                          ) : (
                            <PipelineAddItem isEnabled={this.props.isDistilTabActive}
                              isActive={this.state.openPopup === `generation_${index}`} />
                          )}
                        </CustomPopup>
                      </div>
                    );
                  })}
                </PipelineItemWrapper>
                <PipelinePin rightPin={true}></PipelinePin>
              </PipelineVerticalContainerRow>
            </PipelineVerticalContainer>
            <PipelineVerticalContainer>
              <PipelineVerticalContainerRow>
                <PipelineItemWrapper title={"Answer"} icon={faMessage} backgroundColor='rgba(242,242,242,1)' isEnabled={false}>
                  <div className="pipeline-source-box-title">Answer with cited sources</div>
                </PipelineItemWrapper>
              </PipelineVerticalContainerRow>
            </PipelineVerticalContainer>

          </div>
          <div className='pipeline-config-lock'>{this.props.isDistilTabActive ? <UnlockIcon /> : <LockIcon />}</div>
        </div>
      </div >
    )
  };
}

export default PipelineConfig;
