import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DashboardPage from './dashboard';

function DashboardRoute() {
    // Handle navigation configurations
    const location = useLocation();
    const navigate = useNavigate();

    const selectedOptions = location.state;
    useEffect(() => {
        if (!selectedOptions) {
            navigate("/");
        }
    }, [selectedOptions, navigate]);

    return (
        <DashboardPage />
    );
}

export default DashboardRoute;
