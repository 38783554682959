import React from 'react';
import './popup_ordered_item.css';

interface PopupOrderedItemProps {
    itemTitle?: string;
    isActive?: boolean;
    rank?: number
}

const PopupOrderedItem: React.FC<PopupOrderedItemProps> = ({ itemTitle, rank, isActive }) => {
    return (
        <div className={`popup-ordered-item ${itemTitle ? "popup-ordered-item-title-align" : ''}`}>
            {rank ? (
                <div className='popup-ordered-item-rank'>{rank < 10 ? `0${rank}` : `${rank}`}</div>
            ) : (
                itemTitle ? (
                    <div>{itemTitle}</div>
                ) : (
                    <div className={`popup-ordered-item-bullet ${isActive === undefined ? '' : isActive ? "popup-ordered-item-enabled" : "popup-ordered-item-disabled"}`}></div>
                )
            )}
        </div>
    );
}

export default PopupOrderedItem;
