import "./pipeline_list_element.css"
import { useState } from "react"
import { ReactComponent as TrashIcon } from "../../assets/icons/trash.svg";

type PipelineListElementProps = {
  elementId: string;
  text: string;
  color: string;
  onDeletePipeline: (elementId: string) => void;
  onPipelineCheckboxToggle: () => void;
  onPipelineItemClick: (elementId: string) => void;
  isSelected: boolean
}


const PipelineListElement: React.FC<PipelineListElementProps> = ({ elementId, text, color, onDeletePipeline, onPipelineCheckboxToggle, onPipelineItemClick, isSelected }) => {

  const [isChecked, setIsChecked] = useState(true);

  const toggleCheckbox = () => {
    setIsChecked(!isChecked);
    onPipelineCheckboxToggle()
  };

  return (
    <div className={`pipeline-list-element cursor-pointer ${isSelected ? "pipeline-list-element-active" : ''}`} onClick={() => onPipelineItemClick(elementId)}>
      <span className="colored-circle  cursor-pointer" style={{ backgroundColor: color }} onClick={() => onPipelineItemClick(elementId)}></span>
      <p >{text}</p>
      <div className="right-elements-container">
        <TrashIcon className="medium-icon cursor-pointer trash-icon" onClick={() => onDeletePipeline(elementId)} style={{ marginRight: "8px" }} />
        {/* <FontAwesomeIcon ></FontAwesomeIcon> */}
        <div className="outer-checkbox cursor-pointer" onClick={toggleCheckbox}>
          <div className="inner-checkbox" style={{ visibility: isChecked ? "visible" : "hidden" }}>
          </div>
        </div>
      </div>
    </div >
  );
}

export default PipelineListElement;
