import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import "./document.css";
import { environment } from '../../utils/environment';

const DocumentPage = () => {
    const { documentId } = useParams();
    const [documentData, setDocumentData] = useState(null);

    useEffect(() => {
        const fetchDocumentData = async () => {
            try {
                const response = await axios.get(`${environment.baseUrl}/document/get_document`, { params: { 'doc_id': documentId, 'k': 50 } });
                setDocumentData(response.data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchDocumentData();
    }, [documentId]);

    if (!documentData) {
        return <div>Loading...</div>;
    }

    const { title, text } = documentData;

    return (
        <div className="document-container">
            <h1>{title}</h1>
            <p>{text}</p>
        </div>
    );
};

export default DocumentPage;
