import { ResponsiveBoxPlot } from "@nivo/boxplot";
import { Pipeline } from "../../classes/pipeline";


interface BoxPlotProps {
    data: Pipeline[];
    pipelineColors: string[]
}

const BoxPlotBox: React.FC<BoxPlotProps> = ({ data, pipelineColors }) => {

    let pipelines: any[] = []
    for (let pipeline of data) {
        for (let value of (pipeline.pipelineMetrics.latency as any)['values']) {

            pipelines.push({
                ...pipeline,
                group: pipeline.name,
                "mu": (pipeline.pipelineMetrics.latency as any)['mean'],
                "sd": (pipeline.pipelineMetrics.latency as any)['stdev'],
                "value": value
            })
        }

    }

    const CustomTooltip = (data: any) => (
        <div className="kpi-custom-tooltip tooltip-boxplot">
            <div className="tooltip-boxplot-left-column">
                <span style={{ color: data.color }}>Summary</span>
                <span>N. Samples:<span style={{ color: data.color }}> {data.data.n}</span></span>
                <span>Mean:<span style={{ color: data.color }}> {data.data.mean.toFixed(2)}</span></span>
                <span>Min:<span style={{ color: data.color }}> {data.data.extrema[0].toFixed(2)}</span></span>
                <span>Max:<span style={{ color: data.color }}> {data.data.extrema[1].toFixed(2)}</span></span>
            </div>
            <div className="tooltip-boxplot-right-column">
                <span style={{ color: data.color }}>Quantiles</span>
                {data.data.quantiles.map((quantile: any, index: number) => { return <span>{quantile * 100}%: <span style={{ color: data.color }}>{data.data.values[index].toFixed(2)}</span></span> })}
            </div>
        </div >
    );

    const CustomTick: React.FC<{ x: number; y: number; value?: string, tickIndex: number }> = ({ x, y, value, tickIndex }) => {

        if (data[tickIndex] === undefined) {
            return <></>
        }

        const words = value ? value.split(' ') : [];

        return (
            <g transform={`translate(${x},${y})`}>
                <text
                    x={0}
                    y={25}
                    dy={16}
                    fontSize={13}
                    textAnchor="middle"
                    fill="#757575"
                >
                    {words.map((word, index) => (
                        <tspan key={index} x={0} dy={index === 0 ? "0" : "1.2em"}>{word}</tspan>
                    ))}
                </text>
            </g>
        );
    };


    return (
        <>

            {
                pipelines.length !== 0 ?
                    <ResponsiveBoxPlot
                        data={pipelines}

                        margin={{ top: 30, right: 100, bottom: 70, left: 100 }}
                        minValue={0}
                        maxValue={pipelines.reduce((max, current) => {
                            return current.value > max.value ? current : max;
                        }).value
                        }
                        groupBy="group"
                        colorBy="group"
                        padding={0.8}
                        enableGridX={false}
                        colors={pipelines.map((_: any, index: number) => pipelineColors[index % pipelineColors.length])}
                        theme={{
                            axis:
                            {
                                ticks: {
                                    text: {
                                        fontSize: 13,
                                        fontFamily: "'DM Mono', 'monospace', 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif'"
                                    }
                                }
                            }, translation: {}
                        }}
                        medianWidth={2}
                        medianColor={"white"}
                        whiskerEndSize={0.6}
                        whiskerWidth={1}
                        whiskerColor={{
                            from: 'black',
                            modifiers: [
                                [
                                    'darker',
                                    0.3
                                ]
                            ]
                        }}
                        motionConfig="stiff"
                        tooltip={CustomTooltip}
                        axisLeft={{
                            tickSize: 10,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: 'Latency (Seconds)',
                            legendPosition: 'middle',
                            legendOffset: -50,
                            truncateTickAt: 0
                        }}
                        axisBottom={{
                            tickSize: 0,
                            tickPadding: 5,
                            tickRotation: 0,
                            legendPosition: 'middle',
                            legendOffset: 0,
                            truncateTickAt: 0,
                            renderTick: props => <CustomTick {...props} />,
                        }}
                    />
                    : <></>
            }</>
    );
}

export default BoxPlotBox;
