import { Pipeline } from "../../classes/pipeline";
import { useState } from "react";
import './kpi_box.css';
import { ReactComponent as SizeIcon } from "../../assets/icons/size.svg";
import { ReactComponent as AccuracyIcon } from "../../assets/icons/accuracy.svg";
import { ReactComponent as LatencyIcon } from "../../assets/icons/latency.svg";
import SwarmPlotBox from "./swarm_plot_box";
import BarPlotBox from "./bar_plot_box";
import BoxPlotBox from "./box_plot_box";


interface Props {
    selectedPipelines: Pipeline[];
    pipelineColors: string[]
}

const KPIBox: React.FC<Props> = ({ selectedPipelines, pipelineColors }) => {

    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (tabIndex: number) => {
        setActiveTab(tabIndex);
    };

    return (

        < div className="dashboard-box content-div-style" >
            <div className="dashboard-box-content">
                <p className="small-title">EVALUATION DATA KPI</p>
                <div className="kpi-tabbar">
                    <div className={`kpi-tab cursor-pointer ${activeTab === 0 ? "kpi-tab-active" : ''}`} onClick={() => handleTabClick(0)}>
                        <AccuracyIcon className="kpi-tab-icon" />
                        <span className="custom-font">Factual Accuracy</span>
                    </div>
                    <div className={`kpi-tab cursor-pointer ${activeTab === 1 ? "kpi-tab-active" : ''}`} onClick={() => handleTabClick(1)}>
                        <SizeIcon className="kpi-tab-icon" />
                        <span className="custom-font">Model Size</span>
                    </div>
                    <div className={`kpi-tab cursor-pointer ${activeTab === 2 ? "kpi-tab-active" : ''}`} onClick={() => handleTabClick(2)}>
                        <LatencyIcon className="kpi-tab-icon" />
                        <span className="custom-font">Latency</span>
                    </div>
                </div>
                <div className="kpi-graph-container">
                    {activeTab === 0 ?
                        <BarPlotBox data={selectedPipelines} pipelineColors={pipelineColors} />
                        : (activeTab === 1 ?
                            <SwarmPlotBox data={selectedPipelines} pipelineColors={pipelineColors} />
                            : <BoxPlotBox data={selectedPipelines} pipelineColors={pipelineColors} />)
                    }


                </div>
            </div>
        </div >
    );
}

export default KPIBox;
