export class CustomSelect {

    select_name: string;
    default_placeholder: string;
    options: string[];
    options_values: string[];

    constructor(select_name: string, default_placeholder: string, options: string[]) {
        this.select_name = select_name
        this.default_placeholder = default_placeholder;
        this.options = options;
        this.options_values = this.options.map(option => this._generateOptionValues(option));

    }

    private _generateOptionValues(input: string): string {
        return input.toString().toLowerCase().replace(/[^\w\s]/g, '').replace(/\s+/g, '_');
    }

    public generateSelectState(): { [key: string]: string | undefined } {
        const initialState: { [key: string]: string | undefined } = {};
        initialState[this.select_name] = undefined;
        return initialState;
    }


}


