import { Pipeline } from "../../classes/pipeline";
import { ResponsiveBar } from "@nivo/bar";


interface BarBoxPlotProps {
    data: Pipeline[];
    pipelineColors: string[]
}

const BarPlotBox: React.FC<BarBoxPlotProps> = ({ data, pipelineColors }) => {

    let pipelines: any = data.map(obj => ({ ...obj, "label": obj.name, [obj.name]: obj.pipelineMetrics.factual_accuracy * 100 }));

    const CustomTooltip = (data: any) => (
        <div className="kpi-custom-tooltip">
            <span>Factual Accuracy: <span style={{ color: data.color }}>{data.value}%</span> </span>
        </div>
    );

    const CustomTick: React.FC<{ x: number; y: number; value?: string, tickIndex: number }> = ({ x, y, value, tickIndex }) => {

        if (data[tickIndex] === undefined) {
            return <></>
        }

        const words = value ? value.split(' ') : [];

        return (
            <g transform={`translate(${x},${y})`}>
                <text
                    x={0}
                    y={25}
                    dy={16}
                    fontSize={13}
                    textAnchor="middle"
                    fill="#757575"
                >
                    {words.map((word, index) => (
                        <tspan key={index} x={0} dy={index === 0 ? "0" : "1.2em"}>{word}</tspan>
                    ))}
                </text>
            </g>
        );
    };

    return (
        <>

            {
                pipelines.length !== 0 ?
                    <ResponsiveBar
                        data={pipelines}
                        keys={data.map(obj => (obj.name))}
                        indexBy="label"
                        margin={{ top: 30, right: 100, bottom: 70, left: 100 }}
                        padding={0.9}
                        valueScale={{ type: 'linear', min: 0, max: 90, reverse: false }}
                        colors={pipelines.map((_: any, index: number) => pipelineColors[index % pipelineColors.length])}
                        enableGridY={true}
                        label={() => ""}
                        axisTop={null}
                        axisRight={null}
                        gridYValues={[0, 20, 40, 60, 80]}
                        theme={{
                            axis:
                            {
                                ticks: {
                                    text: {
                                        fontSize: 13,
                                        fontFamily: "'DM Mono', 'monospace', 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif'"
                                    }
                                }
                            }
                        }}
                        axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legendPosition: 'middle',
                            legendOffset: -40,
                            format: (value: number) => `${value}%`,
                            // renderTick: props => <CustomTick {...props} />,
                        }}
                        axisBottom={{
                            tickSize: 0,
                            tickPadding: 5,
                            tickRotation: 0,
                            legendPosition: 'middle',
                            legendOffset: 0,
                            truncateTickAt: 0,
                            renderTick: props => <CustomTick {...props} />,
                        }}
                        tooltip={CustomTooltip}
                    />
                    : <></>
            }</>
    );
}

export default BarPlotBox;
