import React, { useState, ReactNode, MouseEvent, useEffect, useRef } from 'react';
import './custom_popup.css';
import { PopupElement } from '../../classes/popup_element';
import PopupBubble from './popup_bubble';
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import PopupOrderedItem from './popup_ordered_item';
import ComplexPopupItem from './complex_popup_item';
import SimplePopupItem from './simple_popup_item';

enum PopupDisplayType {
    ClickableListView = "CLICKABLELIST",
    BubbleListView = "BUBBLELISTVIEW",
    OrderedListView = "ORDEREDLISTVIEW"
}

interface CustomPopupProps {
    popupID: string,
    isOpen: boolean;
    isEnabled?: boolean;
    onToggle: (popupId?: string, optionId?: string) => void;
    children: ReactNode;
    options: PopupElement[],
    displayType?: PopupDisplayType,
    isOrderedListRanked?: boolean,
    orderedListStatusLabel?: string,
    orderedListTitle?: string,
    complexListView?: boolean
}

const CustomPopup: React.FC<CustomPopupProps> = ({
    popupID,
    isOpen,
    children,
    onToggle,
    options,
    isEnabled = true,
    displayType = PopupDisplayType.ClickableListView,
    isOrderedListRanked = false,
    orderedListStatusLabel,
    orderedListTitle,
    complexListView = true }) => {
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [currentTarget, setcurrentTarget] = useState<any>();
    const popupRef = useRef<HTMLDivElement>(null);

    const handleOpenPopup = (event: MouseEvent<HTMLDivElement>) => {
        if (isEnabled) {
            const rect = event.currentTarget.getBoundingClientRect();
            setcurrentTarget(event.currentTarget);
            setPosition({ x: rect.left + rect.width, y: rect.top });
            onToggle(popupID);
        }
    };

    const handleClickPopupItem = (optionId: string) => {
        onToggle(popupID, optionId);
    };

    useEffect(() => {
        const updatePosition = () => {
            if (isOpen && currentTarget && popupRef.current) {
                const rect = currentTarget.getBoundingClientRect();
                const popupRect = popupRef.current.getBoundingClientRect();
                let newX = rect.left + rect.width;
                let newY = rect.top;

                if (newX + popupRect.width > window.innerWidth) {
                    newX = window.innerWidth - popupRect.width - 10;
                }

                if (newY + popupRect.height > window.innerHeight) {
                    newY = window.innerHeight - popupRect.height - 10;
                }

                setPosition({ x: newX, y: newY });
            }
        };

        const handleClickOutside = (event: any) => {
            if (isOpen && popupRef.current && !popupRef.current.contains(event.target as Node)) {
                onToggle(popupID);
            }
        };

        updatePosition();

        window.addEventListener('resize', updatePosition);
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            window.removeEventListener('resize', updatePosition);
            document.removeEventListener('mousedown', handleClickOutside);
        };


    }, [isOpen, currentTarget, popupRef, popupID, onToggle]);



    return (
        <>
            <div className='popup-trigger' onClick={handleOpenPopup}>
                {children}
            </div>
            {
                isOpen && (
                    <div ref={popupRef} className="pipeline-popup" style={{ top: position.y, left: position.x }}>
                        {displayType === PopupDisplayType.ClickableListView && (
                            <>
                                {complexListView ? (<>
                                    {options.map(option => <ComplexPopupItem key={option.id} itemTitle={option.itemTitle} subTitle={option.subTitle} itemIcon={option.itemIcon} onClick={() => handleClickPopupItem(option.id)}></ComplexPopupItem>)}
                                    <div className={'popup-item-box popup-item-box-last'} onClick={() => handleClickPopupItem("remove")}>
                                        <div className='popup-item-box-footer'>
                                            <div className="popup-item-title">None</div>
                                            <div className='popup-item-icon'>
                                                <CloseIcon></CloseIcon>
                                            </div>
                                        </div>
                                    </div>
                                </>) : (options.map(option => <SimplePopupItem key={option.id} itemTitle={option.itemTitle} itemIcon={option.itemIcon} onClick={() => handleClickPopupItem(option.id)}></SimplePopupItem>))}
                            </>

                        )}
                        {displayType === PopupDisplayType.BubbleListView && (
                            <div className='popup-bubble-box'>
                                {options.map(option => <PopupBubble key={option.id} itemTitle={option.itemTitle} isActive={option.isActive}></PopupBubble>)}
                            </div>
                        )}
                        {displayType === PopupDisplayType.OrderedListView && (
                            <div className='popup-ordered-list-box'>
                                {isOrderedListRanked && (
                                    <div className='popup-ordered-list-box-column'>
                                        <div className='popup-ordered-list-box-header custom-font'>Rank</div>
                                        {options.map((option, index) => <PopupOrderedItem key={option.id + (index + 1)} rank={isOrderedListRanked ? index + 1 : undefined}></PopupOrderedItem>)}
                                    </div>)
                                }
                                {orderedListStatusLabel && (
                                    <div className='popup-ordered-list-box-column'>
                                        <div className='popup-ordered-list-box-header custom-font'>{orderedListStatusLabel}</div>
                                        {options.map(option => <PopupOrderedItem key={option.id + option.isActive} isActive={option.isActive}></PopupOrderedItem>)}
                                    </div>
                                )}

                                {orderedListTitle && (
                                    <div className='popup-ordered-list-box-column'>
                                        <div className='popup-ordered-list-box-header custom-font'>{orderedListTitle}</div>
                                        {options.map(option => <PopupOrderedItem key={option.id + option.itemTitle} itemTitle={option.itemTitle}></PopupOrderedItem>)}
                                    </div>)}
                            </div>
                        )}
                    </div>
                )
            }
        </ >
    );
};

export default CustomPopup;
export { PopupDisplayType };
