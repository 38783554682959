import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { KnowledgeSource } from "../../classes/knowledge_source";
import KnowledgeSourceItem from "./knowledge_source_item";

interface Tab {
  label: string;
  data: KnowledgeSource[];
  isLoading: boolean;
}

interface KnowledgeSourcesProps {
  tabs: Tab[];
  activeTabIndex: number
  onKnowledgeSourceTabClick: (index: number) => void
}

const KnowledgeSourcesTabs: React.FC<KnowledgeSourcesProps> = ({ tabs, activeTabIndex, onKnowledgeSourceTabClick }) => {


  return (
    <div className="dashboard-box content-div-style">
      <div className="dashboard-box-content">
        <div className="tab-container">
          {tabs.map((tab, index) => (
            <div
              key={index}
              className={`tab ${activeTabIndex === index ? "active" : ""}`}
              onClick={() => onKnowledgeSourceTabClick(index)}
            >
              <p className="small-title">{tab.label.toUpperCase()}</p>
            </div>
          ))}
        </div>
        {tabs[activeTabIndex] && tabs[activeTabIndex].isLoading ? (
          <div className="loading-container">
            <span>
              <FontAwesomeIcon className="medium-icon fa-spin" icon={faCircleNotch} />
              Loading...
            </span>
          </div>
        ) : (
          tabs[activeTabIndex] && tabs[activeTabIndex].data.map((item) => {
            return (
              <KnowledgeSourceItem key={item.id} elementId={item.id} source={item} />
            );
          })
        )}
      </div>
    </div>
  );
};

export default KnowledgeSourcesTabs;
