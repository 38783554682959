
import { IdentifiableObject } from "./identifiable_object";
import { PopupElement } from "./popup_element";

export class PipelineConfigState extends IdentifiableObject {

    public name: string;
    public nSections: number;
    public options: PopupElement[]

    constructor(name: string, nSections: number, options: PopupElement[]) {
        super()
        this.name = name
        this.nSections = nSections;
        this.options = options
    }
}


