import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { IdentifiableObject } from "./identifiable_object";
import React from "react";

export class PopupElement extends IdentifiableObject {

    public itemTitle: string;
    public subTitle?: string;
    public itemIcon?: string | IconProp | React.ReactNode
    public isActive?: boolean

    constructor(itemTitle: string, subTitle?: string, itemIcon?: string | IconProp | React.ReactNode, isActive?: boolean) {
        super(itemTitle.trim().toLowerCase())
        this.itemTitle = itemTitle
        this.subTitle = subTitle;
        this.itemIcon = itemIcon;
        this.isActive = isActive;

    }
}


