import { IdentifiableObject } from "./identifiable_object";

export const KnowledgeSourceType = {
    DOCUMENT: 'DOCUMENT',
    QUESTION: 'QUESTION'
}

Object.freeze(KnowledgeSourceType);

export const KnowledgeSourceView = {
    DISTILLATION: 'DISTILLATION',
    LIVE: 'LIVE'
}

Object.freeze(KnowledgeSourceView)

export class KnowledgeSource extends IdentifiableObject {

    public title: string;
    public content: string;
    public relevant?: boolean;
    public type: string;
    public view?: string;

    constructor(title: string, content: string, type: string, relevant?: boolean, id?: string, view?: string) {
        super(id, false)
        this.title = title
        this.content = content;
        this.relevant = relevant;

        if (!Object.values(KnowledgeSourceType).includes(type)) {
            throw new Error(`Invalid knowledge source type: ${type}`);
        }

        this.type = type;

        if (view && !Object.values(KnowledgeSourceView).includes(view)) {
            throw new Error(`Invalid knowledge source type: ${view}`);
        }

        this.view = view;
    }

}
