import React, { ChangeEventHandler } from "react";
import { CustomSelect } from "../../utils/select_input/custom_select";


type IntroSelectProps = {
  selected_value: string | undefined
  custom_select: CustomSelect
  onChange: ChangeEventHandler
}

const IntroSelect: React.FC<IntroSelectProps> = (props) => {

  return (
    <select name={props.custom_select.select_name} className="intro-select" onChange={props.onChange} value={props.selected_value !== undefined ? props.selected_value : 'default'}>
      <option key={props.custom_select.select_name + "_default"} value='default' disabled>{props.custom_select.default_placeholder}</option>
      {props.custom_select.options.map((option, index) => (
        <option key={index} value={props.custom_select.options_values[index]}>
          {option}
        </option>
      ))}
    </select>
  )
}

export default IntroSelect;