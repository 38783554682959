import React, { ChangeEvent, useEffect, useState } from 'react';
import './text_box.css';


interface TextBoxProps {
  placeholder: string;
  onSubmit: (value: string) => void;
  buttonColor?: string;
  buttonText?: string;
  isEnabled?: boolean
}



const TextBox: React.FC<TextBoxProps> = ({ placeholder, onSubmit, buttonColor = "rgba(0,0,0,1)", buttonText = "Evaluate", isEnabled = true }) => {

  const [textBoxValue, setTextBoxValue] = useState('');

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTextBoxValue(event.target.value);
  };

  useEffect(() => {
    if (!isEnabled) {
      setTextBoxValue('');
    }
  }, [isEnabled]);

  const handleSubmit = () => {
    if (isEnabled && textBoxValue.trim() !== "") {
      onSubmit(textBoxValue);
      setTextBoxValue("");
    }



  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (isEnabled && event.key === 'Enter') {
      handleSubmit()
    }
  };


  return (
    <div className="input-container">
      <input type="text" className="input-box" placeholder={placeholder} onChange={onChange} value={textBoxValue} onKeyDown={handleKeyPress} disabled={!isEnabled} />
      <input className={`intro-submit medium-button input-box-button ${!isEnabled ? 'input-box-button-disabled' : 'cursor-pointer'}`} type="submit" value={buttonText} onClick={handleSubmit} style={{ backgroundColor: buttonColor }} disabled={!isEnabled}></input>
    </div>
  );
}

export default TextBox;
