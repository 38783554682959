import "./header.css"
import malted_logo from '../../assets/images/malt_full_black.png'
import user_pic from '../../assets/images/user_pic.png'
import { ReactComponent as SettingsIcon } from "../../assets/icons/settings.svg";
import CustomPopup from "../popup/custom_popup";
import { useState } from "react";
import { PopupElement } from "../../classes/popup_element";
import { ReactComponent as AddIcon } from "../../assets/icons/add.svg";
import { ReactComponent as AccountIcon } from "../../assets/icons/account.svg";
import { ReactComponent as LogoutIcon } from "../../assets/icons/logout.svg";

interface HeaderProps {
  isDistilTabActive: boolean;
  onHeaderTabClick: (flag: boolean) => void;
}



const Header: React.FC<HeaderProps> = ({ isDistilTabActive, onHeaderTabClick }) => {

  const [isSettingsOpen, setIsSettingsOpen] = useState(false)
  const toggleSettingsPopup = (popupId?: string, optionId?: string) => {
    setIsSettingsOpen(!isSettingsOpen);
  }
  return (
    <div className="header content-div-style app-width">
      <img className="malted-logo" src={malted_logo} alt="Malted AI Logo"></img>
      <div className="header-tabs cursor-pointer">
        <div className={`header-tabs-el custom-font ${isDistilTabActive ? "header-tabs-el-active" : ''}`} onClick={() => onHeaderTabClick(true)}>Distillation & Analytics</div>
        <div className={`header-tabs-el custom-font ${!isDistilTabActive ? "header-tabs-el-active" : ''}`} onClick={() => onHeaderTabClick(false)}>Live View</div>
      </div>
      <div className="header-right-menu">
        <img className="header-profile" src={user_pic} alt="Profile Pic"></img>
        <CustomPopup popupID="settings-popup"
          onToggle={toggleSettingsPopup}
          isOpen={isSettingsOpen}
          complexListView={false}
          options={[
            new PopupElement("Start a new Project", undefined, <AddIcon />),
            new PopupElement("Account Settings", undefined, <AccountIcon />),
            new PopupElement("Log Out", undefined, <LogoutIcon />)]}
        >
          <div className="header-settings cursor-pointer">
            <SettingsIcon></SettingsIcon>
          </div>
        </CustomPopup>
      </div>
    </div>
  );
}

export default Header;
