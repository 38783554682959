import { IdentifiableObject } from "./identifiable_object";
import { PipelineMetrics } from "./pipeline_metrics";
import { RagConfig } from "./rag_config";

export class Pipeline extends IdentifiableObject {

    public name: string;
    public pipelineMetrics: PipelineMetrics;
    public pipelineConfig: RagConfig;

    constructor(name: string, pipelineMetrics: PipelineMetrics, ragConfig: RagConfig) {
        super(name.trim().toLowerCase())
        this.name = name;
        this.pipelineMetrics = pipelineMetrics;
        this.pipelineConfig = ragConfig;
    }
}


