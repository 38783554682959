import { useState, useRef } from 'react';
import InputBox from '../text_box/text_box';
import './rag_box.css';
import JSONStreamParser from '../../classes/json_stream';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { environment } from '../../utils/environment';

interface RagBoxProps {
  onPipelineProgressUpdate: (value: string, obj: any) => void;
  onDocumentClick: (value: string) => void;
  onUpdateRagDocIDs: () => void;
}

const RagBox: React.FC<RagBoxProps> = ({ onPipelineProgressUpdate, onUpdateRagDocIDs, onDocumentClick }) => {
  const [generatedOutput, setGeneratedOutput] = useState("");
  const [streamProcessing, setStreamProcessing] = useState(false);
  const parserRef = useRef<any>(null);

  const handleSubmit = (value: string) => {
    if (streamProcessing && parserRef.current) {
      parserRef.current.abort();
    }

    setGeneratedOutput("");
    setStreamProcessing(true);
    onUpdateRagDocIDs()

    const queryParams = new URLSearchParams({
      'pipeline_id': "cached",
      'query': value
    }).toString();
    const newParser = new JSONStreamParser(`${environment.baseUrl}/pipelines/run?${queryParams}`);
    parserRef.current = newParser;
    let relevance = false;

    newParser.on('object', async (obj: any) => {

      onPipelineProgressUpdate(value, obj)

      if (typeof obj === "object" && !Array.isArray(obj) && "token" in obj) {
        if (obj['highlight'] !== null && obj['highlight'] !== "neutral" && !relevance && obj['token'] !== " ") {
          let classLabel = obj['highlight'] === 'positive' ? 'highlight-relevant' : obj['highlight'] === 'negative' ? 'highlight-not-relevant' : '';
          setGeneratedOutput(prevOutput => prevOutput + `<span class="${classLabel}">` + obj['token'] + '</span>');
          relevance = true;
        } else if (obj['highlight'] !== null && obj['highlight'] !== "neutral" && relevance && obj['token'] !== "  \n") {
          let regex = /<\/span>$/;
          setGeneratedOutput(prevOutput => prevOutput.replace(regex, "") + obj['token'] + '</span>');
        } else {
          relevance = false;
          setGeneratedOutput(prevOutput => prevOutput + obj['token']);
        }
      }
    });

    newParser.on('error', (err: Error) => {
      console.error('Error parsing JSON:', err);
    });

    newParser.on('end', () => {
      console.log('Stream ended');
      setStreamProcessing(false);
    });

  };

  const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()
    const documentId = event.currentTarget.getAttribute('href');
    if (documentId !== null) {
      onDocumentClick(documentId);
    }
  };

  return (
    <div className="dashboard-box content-div-style">
      <div className="dashboard-box-content rag-dashboard-box">
        <p className="small-title">QUESTION</p>
        <div className='rag-box-content'>
          <InputBox placeholder={'Enter your question here...'} onSubmit={handleSubmit} buttonText='Run'></InputBox>
          <p className="small-title" style={{ marginTop: "15px" }}>ANSWER</p>
          <div className='generated-answer-container custom-font'>
            <Markdown rehypePlugins={[rehypeRaw]} components={{
              // Override the behavior of anchor tags
              a: ({ node, ...props }) => <a {...props} onClick={handleLinkClick} />
            }}>{generatedOutput}</Markdown>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RagBox;
