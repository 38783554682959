import React, { useState } from "react";
import { faClipboard, faFileLines, faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./knowledge_source_item.css";
import { KnowledgeSource } from "../../classes/knowledge_source";

interface KnowledgeSourceItemProps {
  elementId: string;
  source: KnowledgeSource;
}

const KnowledgeSourceItem: React.FC<KnowledgeSourceItemProps> = ({ elementId, source }) => {
  const [showPopover, setShowPopover] = useState(false);

  const toggleAccordion = () => {
    const accordion = document.getElementById(elementId);
    if (!accordion) return;

    const accordionContent = accordion.querySelector('.accordion-content') as HTMLElement;
    const chevronIcon = accordion.querySelector('.fa-chevron-down') as HTMLElement;

    if (!accordionContent || !chevronIcon) return;

    const isOpen = accordionContent.style.display !== 'none';
    accordionContent.style.display = isOpen ? 'none' : 'block';
    chevronIcon.style.transform = isOpen ? 'rotate(0deg)' : 'rotate(180deg)';
    chevronIcon.style.transition = 'transform 0.3s ease';
  };

  const renderDocumentLink = () => {
    if (source.type === 'DOCUMENT') {
      return (
        <a href={`/documents/${elementId}`} className="document-link" target="_blank" rel="noreferrer">
          <FontAwesomeIcon
            className="medium-icon"
            icon={faFileLines}
            style={{ color: '#000' }}
          />
        </a>
      );
    }
    return (
      <FontAwesomeIcon
        className='medium-icon'
        icon={faQuestionCircle}
      />
    );
  };

  const getCircleElement = () => {
    if (source.view === undefined || source.view === 'DISTILLATION') {
      return null;
    } else {
      let circleClass;
      if (source.relevant === true) {
        circleClass = 'colored-circle relevant-circle';
      } else if (source.relevant === false) {
        circleClass = 'colored-circle not-relevant-circle';
      } else {
        circleClass = 'colored-circle un-judged-circle';
      }
      return <span className={circleClass}></span>;
    }
  }

  function copyToClipboard(text: string) {
    navigator.clipboard.writeText(text)
      .then(() => {
        setShowPopover(true);
        setTimeout(() => {
          setShowPopover(false);
        }, 500);
      })
      .catch((error) => {
      });
  }

  return (
    <div className="accordion-item" id={elementId}>
      <div className="accordion-title-box" onClick={toggleAccordion}>
        {getCircleElement()}
        <span className="accordion-item-title">{source.title}</span>
        <div className="right-elements-container">
          <span className="document-id-span">{elementId}</span>
          {renderDocumentLink()}
          <FontAwesomeIcon
            className='small-icon'
            icon={faChevronDown}
          ></FontAwesomeIcon>
        </div>
      </div>
      <div className="accordion-content" style={{ display: "none" }}>
        <div><span>{source.content}</span></div>
        {/* <div className="accordion-content-clipboard-container">
          <span className={`copied-text-popover ${showPopover ? 'show' : ''}`}>Text Copied!</span>
          <FontAwesomeIcon className="accordion-content-clipboard" icon={faClipboard} onClick={() => copyToClipboard(source.content)}></FontAwesomeIcon>
        </div> */}
      </div>
    </div>
  );
};

export default KnowledgeSourceItem;
