import './pipeline_item_wrapper.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';


interface PipelineItemWrapperProps {
    title?: string;
    icon?: IconProp;
    children: React.ReactNode
    backgroundColor?: string
    isEnabled?: boolean
    isActive?: boolean
}

const PipelineItemWrapper: React.FC<PipelineItemWrapperProps> = ({
    title,
    icon,
    backgroundColor = "rgba(255,255,255,1)",
    children,
    isActive = false,
    isEnabled = true }) => {

    return (
        <div className="pipeline-source-box-wrapper">
            <div className={`pipeline-source-box ${(isActive ? "pipeline-source-box-active" : "")} ${(isEnabled ? 'pipeline-source-box-enabled' : 'pipeline-source-box-disabled')}`}
                style={{ backgroundColor: backgroundColor }}>
                {title !== undefined || icon !== undefined ?
                    <div className='pipeline-source-box-header'>
                        <div className="pipeline-source-box-subtitle custom-font">{title}</div>
                        {icon !== undefined ? <FontAwesomeIcon
                            className="medium-icon"
                            icon={icon}
                        ></FontAwesomeIcon> : <></>}
                    </div> :
                    <></>}

                {children}
            </div>
        </div >

    );
}

export default PipelineItemWrapper;
