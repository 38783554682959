import { Pipeline } from "../../classes/pipeline";
import PipelineListElement from "./pipeline_list_element";

interface PipelineListProps {
    availablePipelines: Pipeline[];
    onDeletePipeline: (elementId: string) => void;
    onPipelineCheckboxToggle: (elementId: string) => void;
    onPipelineItemClick: (elementId: string) => void;
    selectedPipeline?: Pipeline
    pipelineColors: string[]
}

const PipelineList: React.FC<PipelineListProps> = ({ availablePipelines, onDeletePipeline, onPipelineCheckboxToggle, onPipelineItemClick, selectedPipeline, pipelineColors }) => {


    return (
        <div className="dashboard-box content-div-style">
            <div className="dashboard-box-content">
                <p className="small-title">PIPELINE COMPARISON</p>
                {availablePipelines.map((pipeline, index) => (
                    <PipelineListElement
                        key={pipeline.id}
                        elementId={pipeline.id}
                        text={pipeline.name}
                        color={pipelineColors[index % pipelineColors.length]}
                        onDeletePipeline={onDeletePipeline}
                        onPipelineCheckboxToggle={() => onPipelineCheckboxToggle(pipeline.id)}
                        onPipelineItemClick={() => onPipelineItemClick(pipeline.id)}
                        isSelected={selectedPipeline?.id === pipeline.id}
                    />
                ))}
            </div>
        </div>
    );
};

export default PipelineList;
