import IntroBox from '../components/intro_box/intro_box';
import './landing.css'
function LandingPage() {

    return (
        <div className='app-container'>
            <div className='intro-box-flexbox'>
                <IntroBox></IntroBox>
            </div>
        </div>
    );
}

export default LandingPage;
