import React from 'react';
import Header from '../components/header/header';
import "./dashboard.css"
import PipelineList from '../components/pipeline_list/pipeline_list';
import KPIBox from '../components/kpi_box/kpi_box';
import PipelineConfig from '../components/pipeline_config/pipeline_config';
import RagBox from '../components/rag_box/rag_box';
import { KnowledgeSource, KnowledgeSourceType, KnowledgeSourceView } from '../classes/knowledge_source';
import { Pipeline } from '../classes/pipeline';
import KnowledgeSourcesTabs from '../components/knowledge_source_list/knowledge_source_list';
import axios from 'axios';
import { PipelineMetrics } from '../classes/pipeline_metrics';
import { RagConfig } from '../classes/rag_config';
import { PopupElement } from '../classes/popup_element';
import { ReactComponent as MistralIcon } from "../assets/icons/mistral.svg";
import { ReactComponent as OpenAIIcon } from "../assets/icons/open_ai.svg";
import { ReactComponent as DistilledMaltIcon } from "../assets/icons/distil_default.svg";
import { ReactComponent as OpenSearchIcon } from "../assets/icons/opensearch.svg";
import { environment } from '../utils/environment';


let seenRagDocIDs = new Set()

interface DefaultRagConfigs {
    [key: string]: RagConfig;
}

const defaultRagConfigs: DefaultRagConfigs = {
    'gpt4': new RagConfig([new PopupElement("GPT4", "1.7TR", <OpenAIIcon />)], [new PopupElement("BM25", undefined, <OpenSearchIcon />), undefined!, undefined!], [new PopupElement("GPT4", "1.7TR", <OpenAIIcon />)], [new PopupElement("GPT4", "1.7TR", <OpenAIIcon />)]),
    'open_source': new RagConfig([new PopupElement("Mistral Small", "7B", <MistralIcon />)], [new PopupElement("BM25", undefined, <OpenSearchIcon />), undefined!, undefined!], [new PopupElement("Mistral Small", "7B", <MistralIcon />)], [new PopupElement("Mistral Small", "7B", <MistralIcon />)]),
    'open_source_advanced': new RagConfig([new PopupElement("Mistral Large", "7x8B", <MistralIcon />)], [new PopupElement("BM25", undefined, <OpenSearchIcon />), undefined!, undefined!], [new PopupElement("Mistral Large", "7x8B", <MistralIcon />)], [new PopupElement("Mistral Large", "7x8B", <MistralIcon />)]),
    'distilled_pipeline': new RagConfig([new PopupElement("Distil Mistral", "7B", <DistilledMaltIcon />)], [new PopupElement("BM25", undefined, <OpenSearchIcon />), undefined!, undefined!], [new PopupElement("Distil T5", "1.7TR", <DistilledMaltIcon />)], [new PopupElement("Distil Mistral", "7B", <DistilledMaltIcon />)])
}

const pipelineColors: string[] = [
    "#F53CC2",
    "#09CAA5",
    "#929DFF",
    "#4F16F3",
    "#FFC47E",
    "#505050",
    "#FF6E6E",
    "#F1BFE0"
]

interface DashboardPageState {
    availablePipelines: Pipeline[];
    selectedPipelines: Pipeline[];
    knowledgeSources: KnowledgeSource[];
    questions: KnowledgeSource[];
    isContentLoading: boolean;
    isDistilTabActive: boolean;
    ragResults: KnowledgeSource[];
    selectedPipeline?: Pipeline;
    queryExpansionItems: PopupElement[]
    initialSearchResultsItems: PopupElement[]
    rankedSearchResultsItems: PopupElement[]
    activeTabIndex: number,
}

class DashboardPage extends React.Component<any, DashboardPageState> {

    constructor(props: any) {
        super(props);
        this.state = {
            availablePipelines: [],
            selectedPipelines: [],
            isDistilTabActive: true,
            ragResults: [],
            knowledgeSources: [],
            questions: [],
            isContentLoading: true,
            selectedPipeline: undefined,
            queryExpansionItems: [],
            initialSearchResultsItems: [],
            rankedSearchResultsItems: [],
            activeTabIndex: 0
        }
    }


    componentDidMount(): void {
        if (this.state.knowledgeSources.length === 0) {
            this.fetchQueriesAndDocs("document", "documents", "knowledgeSources");
        }
        if (this.state.questions.length === 0) {
            this.fetchQueriesAndDocs("query", "queries", "questions");
        }
        if (this.state.availablePipelines.length === 0 && this.state.selectedPipelines.length === 0) {
            this.fetchPipelineMetrics(['gpt4', 'open_source', 'open_source_advanced']);
        }
    }

    private fetchQueriesAndDocs = (inputType: string, responseDataKey: string, stateKey: string): void => {
        this.setState({ isContentLoading: true } as Pick<DashboardPageState, keyof DashboardPageState>);
        axios.get(`${environment.baseUrl}/document/get_random_documents_or_queries`, { params: { 'input_type': inputType, 'k': 50 } })
            .then(response => {
                this.setState({
                    isContentLoading: false,
                    [stateKey]: response.data[responseDataKey].map((item: any, index: number) => new KnowledgeSource(`${inputType.charAt(0).toUpperCase() + inputType.slice(1)} ${index + 1}`, item.text, inputType === "document" ? KnowledgeSourceType.DOCUMENT : KnowledgeSourceType.QUESTION, undefined, item.doc_id || item.query_id, KnowledgeSourceView.DISTILLATION))
                } as Pick<DashboardPageState, keyof DashboardPageState>);
            })
            .catch(error => {
                console.error(error);
            });
    }

    private fetchPipelineMetrics = (pipelineIds: string[]): void => {
        let promises = pipelineIds.map(pipelineId => {
            return axios.get(`${environment.baseUrl}/metrics/get_metrics`, { params: { 'pipeline_id': pipelineId } })
                .then(response => {
                    let data = response.data;
                    let ragConfig = defaultRagConfigs[pipelineId];
                    let metrics = new PipelineMetrics(data['model_size'], data['model_size_reduced'], data['latency'], data['factual_accuracy']);
                    return new Pipeline(data['name'], metrics, ragConfig);
                })
                .catch(error => {
                    console.error(error);
                    return null;
                });
        });

        Promise.all(promises)
            .then(pipelines => {
                pipelines = pipelines.filter(pipeline => pipeline !== null);

                this.setState({
                    availablePipelines: pipelines as Pipeline[],
                    selectedPipelines: pipelines as Pipeline[]
                });
            });

    }


    // Method used to delete pipelines from the Pipeline List
    private onDeletePipeline = (elementId: string) => {
        this.setState({
            availablePipelines: this.state.availablePipelines.filter(obj => obj.id !== elementId),
            selectedPipelines: this.state.selectedPipelines.filter(obj => obj.id !== elementId)
        });
    };

    // Method used to toggle selections from the Pipeline list
    private onPipelineCheckboxToggle = (elementId: string) => {

        if (this.state.selectedPipelines.some(obj => obj.id === elementId)) {
            this.setState({
                selectedPipelines: this.state.selectedPipelines.filter(obj => obj.id !== elementId)
            })

        } else {
            this.setState({
                selectedPipelines: [...this.state.selectedPipelines, this.state.availablePipelines.find(obj => obj.id === elementId)!]
            })
        }

    };

    private onPipelineItemClick = (elementId: string) => {

        let clickedPipeline = this.state.availablePipelines.find(obj => obj.id === elementId);
        this.setState({
            selectedPipeline: clickedPipeline
        });
    }

    private onHeaderTabClick = (flag: boolean) => {
        this.setState({
            isDistilTabActive: flag,
            queryExpansionItems: flag ? [] : this.state.queryExpansionItems,
            initialSearchResultsItems: flag ? [] : this.state.initialSearchResultsItems,
            rankedSearchResultsItems: flag ? [] : this.state.rankedSearchResultsItems,
            ragResults: []
        });
    }

    private createNewPipeline = (pipeline: Pipeline) => {

        const updatedPipelines = [...this.state.availablePipelines];
        if (updatedPipelines.some(obj => obj.id === pipeline.id)) {
            alert("You can't create two pipelines with the same name!");
        } else {
            updatedPipelines.push(pipeline)

            this.setState({
                availablePipelines: updatedPipelines,
                selectedPipelines: [...this.state.selectedPipelines, pipeline]
            })
        }

    };

    handleDocumentIdClick = (documentId: string) => {
        const accordionContent = document.getElementById(documentId)?.querySelector('.accordion-content') as HTMLElement;
        const chevronIcon = document.getElementById(documentId)?.querySelector('.fa-chevron-down') as HTMLElement;

        if (!accordionContent || !chevronIcon) return;

        let accordionContentParent = accordionContent.parentElement
        if (accordionContentParent !== null && accordionContent !== undefined) {
            accordionContentParent.scrollIntoView(true);
        }


        const isContentVisible = accordionContent.style.display !== 'none';

        const elements = document.querySelectorAll('.accordion-content');
        const chevronIcons = document.querySelectorAll('.fa-chevron-down');

        elements.forEach((element: any) => {
            element.style.display = 'none';
        });

        chevronIcons.forEach((chevronIcon: any) => {
            chevronIcon.style.transform = 'rotate(0deg)';
            chevronIcon.style.transition = 'transform 0.3s ease';
        });

        accordionContent.style.display = isContentVisible ? 'none' : 'block';
        chevronIcon.style.transform = isContentVisible ? 'rotate(0deg)' : 'rotate(180deg)';
        chevronIcon.style.transition = 'transform 0.3s ease';
    };

    updateSeenRagDocIDs = () => {
        this.setState({
            ragResults: []
        });
        seenRagDocIDs = new Set();
    }

    pipelineProgressUpdate = (query: string, obj: any) => {

        if (typeof obj === "object" && "doc_id" in obj) {

            const docId = obj["doc_id"]
            let relevance = obj['relevance']
            if (relevance === "relevant") {
                relevance = true;
            } else if (relevance === "non-relevant") {
                relevance = false;
            } else {
                relevance = undefined;
            }

            if (!seenRagDocIDs.has(docId)) {
                seenRagDocIDs.add(docId);
                axios.get(`${environment.baseUrl}/document/get_snippet`, { params: { 'doc_id': docId, 'query': query } })
                    .then(response => {
                        const { snippet } = response.data;
                        let ragResult = new KnowledgeSource("Document " + (this.state.ragResults.length + 1), snippet, KnowledgeSourceType.DOCUMENT, relevance, docId, KnowledgeSourceView.LIVE);
                        this.setState({
                            ragResults: [...this.state.ragResults, ragResult]
                        });
                    })
                    .catch(error => {
                        console.error('Error fetching snippet:', error);
                    });
            }
        } else if (typeof obj === "object" && "expansion" in obj) {
            let popupItemsTemp = []
            for (let concept of obj['expansion']) {
                const popupElementObj = new PopupElement(concept, undefined, undefined, undefined)
                popupItemsTemp.push(popupElementObj);

            }
            this.setState({
                queryExpansionItems: popupItemsTemp
            })

        } else if (typeof obj === "object" && "results" in obj && obj['source'] === "engine") {
            const searchResults = obj['results']
            const relevances = obj['relevances']

            let popupItemsTemp = []


            const docRelevancePairs = searchResults.map((searchResult: any, index: number) => {
                let relevance = undefined
                if (relevances && relevances[index]) {
                    relevance = relevances[index] === 'relevant';
                }
                return {
                    docId: searchResult.doc_id,
                    relevance: relevance
                };
            });


            for (let pair of docRelevancePairs) {
                const popupElementObj = new PopupElement(pair.docId, undefined, undefined, pair.relevance)
                popupItemsTemp.push(popupElementObj)
            }


            this.setState({
                initialSearchResultsItems: popupItemsTemp
            })

        } else if (typeof obj === "object" && "results" in obj && obj['source'] === "reranker") {
            const searchResults = obj['results']
            const relevances = obj['relevances']

            let popupItemsTemp = []


            const docRelevancePairs = searchResults.map((searchResult: any, index: number) => {
                let relevance = undefined
                if (relevances && relevances[index]) {
                    relevance = relevances[index] === 'relevant';
                }
                return {
                    docId: searchResult.doc_id,
                    relevance: relevance
                };
            });


            for (let pair of docRelevancePairs) {
                const popupElementObj = new PopupElement(pair.docId, undefined, undefined, pair.relevance)
                popupItemsTemp.push(popupElementObj)
            }


            this.setState({
                rankedSearchResultsItems: popupItemsTemp
            })
        }
    }

    knowledgeSourceTabClick = (index: number) => {
        this.setState({
            activeTabIndex: index
        });
    }


    render() {
        return (
            <div className='app-container'>
                <Header isDistilTabActive={this.state.isDistilTabActive} onHeaderTabClick={this.onHeaderTabClick} />
                <div className='dashboard-container app-width'>
                    <div className='dashboard-container-row'>
                        {this.state.isDistilTabActive ?
                            <>
                                <div className='dashboard-container-element dashboard-container-element-25 dashboard-container-element-padding'>
                                    <PipelineList
                                        availablePipelines={this.state.availablePipelines}
                                        onDeletePipeline={this.onDeletePipeline}
                                        onPipelineCheckboxToggle={this.onPipelineCheckboxToggle}
                                        onPipelineItemClick={this.onPipelineItemClick}
                                        selectedPipeline={this.state.selectedPipeline}
                                        pipelineColors={pipelineColors}></PipelineList>
                                </div>
                                <div className='dashboard-container-element dashboard-container-element-50 dashboard-container-element-padding'>
                                    <KPIBox selectedPipelines={this.state.selectedPipelines} pipelineColors={pipelineColors}></KPIBox>
                                </div>
                            </> :
                            <div className='dashboard-container-element dashboard-container-element-75 dashboard-container-element-padding'>
                                <RagBox onPipelineProgressUpdate={this.pipelineProgressUpdate} onUpdateRagDocIDs={this.updateSeenRagDocIDs} onDocumentClick={this.handleDocumentIdClick}></RagBox>
                            </div>}
                        <div className='dashboard-container-element dashboard-container-element-25'>
                            <KnowledgeSourcesTabs
                                tabs={this.state.isDistilTabActive ?
                                    [
                                        { label: "Knowledge Sources", data: this.state.knowledgeSources, isLoading: this.state.isContentLoading },
                                        { label: "Questions", data: this.state.questions, isLoading: this.state.isContentLoading }
                                    ] :
                                    [{ label: "RAG Results", data: this.state.ragResults, isLoading: this.state.isContentLoading }]}
                                activeTabIndex={this.state.isDistilTabActive ? this.state.activeTabIndex : 0}
                                onKnowledgeSourceTabClick={this.knowledgeSourceTabClick}
                            />
                        </div>
                    </div>
                    <div className='dashboard-container-row'>
                        <div className='dashboard-container-element dashboard-container-element-100'>
                            <PipelineConfig
                                createNewPipeline={this.createNewPipeline}
                                selectedPipeline={this.state.selectedPipeline}
                                isDistilTabActive={this.state.isDistilTabActive}
                                queryExpansionItems={this.state.queryExpansionItems}
                                initialSearchResultsItems={this.state.initialSearchResultsItems}
                                rankedSearchResultsItems={this.state.rankedSearchResultsItems}></PipelineConfig>
                        </div>
                    </div>
                </div>
            </div >
        )
    }

}

export default DashboardPage;
