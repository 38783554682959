import React from 'react';
import './complex_popup_item.css';
import './simple_popup_item.css';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface SimplePopupItemProps {
    itemTitle: string;
    itemIcon?: string | IconProp | React.ReactNode;
    onClick: () => void
}

const SimplePopupItem: React.FC<SimplePopupItemProps> = ({ itemTitle, itemIcon, onClick }) => {
    return (
        <div className='popup-item-box popup-items-simple cursor-pointer' onClick={onClick}>
            <div className='popup-item-box-footer'>
                <div className="popup-item-title popup-item-title-simple">{itemTitle}</div>
                <div className='popup-item-icon'>
                    {itemIcon && typeof itemIcon === 'string' ? (
                        <img src={itemIcon} alt="Model Icon" />
                    ) : (
                        React.isValidElement(itemIcon) ? (
                            itemIcon
                        ) : (
                            <FontAwesomeIcon icon={itemIcon as IconProp} />
                        )
                    )}
                </div>
            </div>
        </div >
    );
}

export default SimplePopupItem;
