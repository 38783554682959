import React from 'react';
import './pipeline_pin.css';


interface PipelinePinProps {
    leftPin?: boolean,
    rightPin?: boolean
}

const PipelinePin: React.FC<PipelinePinProps> = ({ leftPin = false, rightPin = false }) => {

    return (
        <div className='pipeline-pin-box-wrapper'>
            <div className={`pipeline-pin ${!leftPin && !rightPin ? "pipeline-pin-hidden" : ''}`}>
                <div className={`pin-circle ${leftPin ? "pin-circle-left" : 'pipeline-pin-hidden'}`} ></div>
                <div className={`pin-circle ${rightPin ? "pin-circle-right" : 'pipeline-pin-hidden'}`} ></div>
            </div>
        </div >
    );
}

export default PipelinePin;
