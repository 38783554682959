import React from 'react';
import './pipeline_model.css';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface PipelineModelProps {
    modelName: string;
    modelSize?: string;
    modelIcon?: string | IconProp | React.ReactNode;
    isActive?: boolean;
    isEnabled?: boolean;
}

const PipelineModel: React.FC<PipelineModelProps> = ({ modelName, modelSize, modelIcon, isActive = false, isEnabled = true }) => {
    return (
        <div className={`pipeline-model-box ${isActive ? "pipeline-model-box-active" : ""} ${(isEnabled ? 'pipeline-model-box-enabled' : 'pipeline-model-box-disabled')}`}>
            <div className="pipeline-source-box-title">{modelName}</div>
            <div className='pipeline-model-box-footer'>
                <div className="pipeline-model-size" style={modelSize ? { visibility: 'visible' } : { visibility: 'hidden' }}>{modelSize}</div>
                <div className='pipeline-model-icon'>
                    {modelIcon && typeof modelIcon === 'string' ? (
                        <img src={modelIcon} alt="Model Icon" />
                    ) : (
                        React.isValidElement(modelIcon) ? (
                            modelIcon
                        ) : (
                            <FontAwesomeIcon icon={modelIcon as IconProp} />
                        )
                    )}

                </div>

            </div>
        </div >
    );
}

export default PipelineModel;
